
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { FaCheckCircle, FaCopy, FaEllipsisH, FaTrashAlt } from 'react-icons/fa'
import { useDeleteContactMutation } from '../../store/services/contactService';
import { useDeleteCouponMutation } from '../../store/services/BusinessCoupons';

const Coupons = ({notification}) => {

  const [isCopied, setIsCopied] = useState(false);
  const [deleteCoupon] = useDeleteCouponMutation();
  const handleDelete = async () => {
    try {
      // Assuming deleteCoupon is a function that handles the API call to delete the coupon
      const response = await deleteCoupon(notification._id); 
      if (response.success) {
        toast.success("Coupon deleted successfully!");
      } else {
        toast.error("Failed to delete coupon!");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the coupon.");
    }
  };

  return (
    
    <div
    key={notification._id}
    className={`px-4 py-3 hover:bg-gray-50`}
  >
    <div className="flex items-center">
      <div className="flex-shrink-0 mr-3">
        <div className="relative">
          <img
            src={"https://api.petzoy.in/mainlogo/logo.png"}
            alt="User avatar"
            className="w-12 h-12 rounded-full"
          />
          <div className={`absolute -bottom-1 -right-1 bg-green-500 rounded-full p-1`}>
          <FaCheckCircle className="text-white"/>
          </div>
        </div>
      </div>
      <div className="flex-grow">
        <p className="text-sm text-gray-900">
          <span className="font-semibold">{notification?.code} </span>
          <br/>
          {notification?.description}
        </p>
        <p className="text-xs text-gray-500 mt-1">
        Expiry Date : {notification?.expiryDate?.split("T")[0]} 
        </p>
        <p className="text-xs text-gray-500">
        Minimum Order  : {notification?.minimumOrderValue} 
        </p>
       
      </div>
      <div className="flex-shrink-0 ml-2">
        <button
          // onClick={() => markAsRead(notification.id)}
          className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100"
        >
         

{isCopied ? <p className="text-xs text-green-500">Copied to clipboard!</p>: <FaCopy className="w-5 h-5" onClick={() => {
            navigator.clipboard.writeText(notification.code).then(() => {
              toast.success("Welcome to Petzoy");
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
            });
          }} />
        }
        </button>
        <button
            onClick={handleDelete}
            className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 ml-3"
          >
            <FaTrashAlt className="w-5 h-5" />
          </button>
      </div>
    </div>
  </div>
  )
}

export default Coupons
