import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const couponService = createApi({
  reducerPath: "coupons",
  tagTypes: ["coupons"],
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.petzoy.in/api/",
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.adminToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Add a new coupon
    createCoupon: builder.mutation({
      query: (data) => ({
        url: "business/coupons",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["coupons"],
    }),

    // Delete a coupon by ID
    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `/coupons/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["coupons"],
    }),

    // Get all coupons for a specific business
    getCouponsByBusiness: builder.query({
      query: (businessId) => ({
        url: `/coupons/business/${businessId}`,
        method: "GET",
      }),
      providesTags: ["coupons"],
    }),
  }),
});

export const {
  useCreateCouponMutation,
  useDeleteCouponMutation,
  useGetCouponsByBusinessQuery,
} = couponService;

export default couponService;
