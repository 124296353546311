import React, { useState, useCallback, useMemo } from 'react';
import { FaCopy, FaWhatsapp, FaFacebookF, FaEnvelope, FaPaw, FaGift, FaShare, FaTrophy, FaTimes, FaUserFriends, FaUser } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const colors = {
  primary: '#6B46C1',
  secondary: '#76E4F7',
  accent: '#F6AD55',
  light: '#EDF2F7',
  dark: '#2D3748',
};

const ShareButton = ({ icon: Icon, label, onClick, className }) => (
  <motion.button
    whileHover={{ scale: 1.01 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
    className={`w-full py-2.5 sm:py-3 rounded-lg font-medium transition-all duration-300 flex items-center justify-center gap-2 text-xs sm:text-sm ${className}`}
  >
    <Icon className="text-base sm:text-lg" />
    <span className="font-medium">{label}</span>
  </motion.button>
);

const StatCard = ({ value, label, icon: Icon }) => (
  <div className="bg-white rounded-lg p-3 sm:p-4 text-center border border-purple-100 hover:border-purple-200 transition-all duration-300">
    <div className="flex items-center justify-center mb-1.5 sm:mb-2">
      <div className="bg-purple-50 p-1.5 sm:p-2 rounded-lg">
        <Icon className="text-base sm:text-lg text-purple-600" />
      </div>
    </div>
    <span className="block text-lg sm:text-xl font-bold text-purple-800 mb-0.5 sm:mb-1">{value}</span>
    <span className="text-[10px] sm:text-xs text-gray-600">{label}</span>
  </div>
);

const UserCard = ({ name, date }) => (
  <div className="flex items-center gap-3 p-3 border-b border-purple-100 last:border-b-0">
    <div className="bg-purple-50 p-2 rounded-full">
      <FaUser className="text-sm text-purple-600" />
    </div>
    <div className="flex-1">
      <p className="text-sm font-medium text-gray-800">{name}</p>
      <p className="text-xs text-gray-500">{date}</p>
    </div>
  </div>
);

const ReferralSystem = ({ referralCode, referUser }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Use provided referred users or fallback to static list
  const referredUsers = referUser || [];
  const [copied, setCopied] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const onClose = () => setIsOpen(false);

  const shareData = useMemo(() => ({
    title: 'Join Petzoy',
    text: `🐶 Check out Petzoy - your one-stop shop for all pet needs. Use my referral code ${referralCode}!`,
    url: `https://petzoy.in/register/${referralCode}`
  }), [referralCode]);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(referralCode);
      setCopied(true);
      setShowToast(true);
      setTimeout(() => {
        setCopied(false);
        setShowToast(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  }, [referralCode]);

  const shareViaWhatsApp = useCallback(() => {
    const message = encodeURIComponent(`${shareData.text} ${shareData.url}`);
    window.open(`https://wa.me/?text=${message}`, '_blank');
  }, [shareData]);

  const shareViaEmail = useCallback(() => {
    const subject = encodeURIComponent("Join me on Petzoy - Your Pet's Perfect Destination");
    const body = encodeURIComponent(`Hey!\n\nI thought you might be interested in Petzoy - they have amazing products for pets.\n\nUse my referral code: ${referralCode}\n\nVisit: ${shareData.url}`);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  }, [referralCode, shareData.url]);

  const shareViaFacebook = useCallback(() => {
    window.open(`https://www.facebook.com/share.php?u=${encodeURIComponent(shareData.url)}`, '_blank');
  }, [shareData.url]);

  const handleNativeShare = useCallback(async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
      }
    } catch (err) {
      console.error('Share failed:', err);
    }
  }, [shareData]);

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 left-4 sm:bottom-20 sm:left-8 bg-gradient-to-r from-purple-600 to-purple-500 text-white px-4 py-2 rounded-full shadow-lg flex items-center gap-2 hover:shadow-xl transition-shadow group z-[9999]"
      >
        <FaUserFriends className="text-lg group-hover:animate-bounce" />
        <span className="font-medium">Refer Users</span>
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={onClose}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
            />

            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="fixed inset-0 z-50 flex items-center justify-center"
            >
              <div className="w-full max-w-2xl p-4">
                <motion.div className="w-full bg-white shadow-xl rounded-2xl relative">
                  <button
                    onClick={onClose}
                    className="absolute -right-3 -top-3 bg-white w-8 h-8 rounded-full flex items-center justify-center shadow-lg border border-gray-100 z-50 hover:bg-gray-50 transition-colors group"
                  >
                    <FaTimes className="text-gray-500 text-sm group-hover:text-gray-700" />
                  </button>

                  <div className="p-4 sm:p-6">
                    <motion.div className="relative overflow-hidden bg-gradient-to-r from-purple-600 to-purple-500 rounded-xl p-4 sm:p-6 text-white shadow-lg mb-4">
                      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAwIDEwMG0tMTAwIDBhMTAwIDEwMCAwIDEgMCAyMDAgMGExMDAgMTAwIDAgMSAwIC0yMDAgMFoiIGZpbGw9Im5vbmUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1vcGFjaXR5PSIwLjEiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==')] opacity-20"></div>

                      <div className="relative z-10">
                        <div className="flex items-center justify-center mb-3">
                          <div className="bg-white/20 p-2 rounded-lg backdrop-blur-sm">
                            <FaPaw className="text-2xl text-cyan-200" />
                          </div>
                        </div>
                        <h1 className="text-xl sm:text-2xl font-bold text-center mb-2">
                          Share Petzoy with Friends
                        </h1>
                        <p className="text-center text-xs sm:text-sm text-white/90 max-w-md mx-auto">
                          Invite friends and earn rewards together
                        </p>
                      </div>
                    </motion.div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                      <motion.div
                        whileHover={{ translateY: -2 }}
                        className="bg-white rounded-xl shadow-sm p-4 border border-purple-100"
                      >
                        <div className="flex items-center gap-2 mb-3">
                          <div className="bg-purple-50 p-1.5 rounded-lg">
                            <FaGift className="text-base text-purple-600" />
                          </div>
                          <h2 className="text-base font-bold text-gray-800">
                            Your Referral Code
                          </h2>
                        </div>
                        <div className="space-y-3">
                          <div className="bg-purple-50 p-3 rounded-lg border border-dashed border-purple-300">
                            <span className="block text-base font-mono font-bold text-purple-600 text-center tracking-wider">
                              {referralCode}
                            </span>
                          </div>
                          <motion.button
                            whileHover={{ scale: 1.01 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={copyToClipboard}
                            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 rounded-lg font-medium transition-all duration-300 flex items-center justify-center gap-2 text-xs"
                          >
                            <FaCopy
                              className={`text-xs ${
                                copied ? "opacity-0" : ""
                              }`}
                            />
                            <span>{copied ? "Copied!" : "Copy Code"}</span>
                          </motion.button>
                        </div>
                      </motion.div>

                      <motion.div
                        whileHover={{ translateY: -2 }}
                        className="bg-white rounded-xl shadow-sm p-4 border border-purple-100"
                      >
                        <div className="flex items-center gap-2 mb-3">
                          <div className="bg-purple-50 p-1.5 rounded-lg">
                            <FaShare className="text-base text-purple-600" />
                          </div>
                          <h2 className="text-base font-bold text-gray-800">
                            Share with Friends
                          </h2>
                        </div>
                        <div className="space-y-2">
                          <ShareButton
                            icon={FaWhatsapp}
                            label="Share via WhatsApp"
                            onClick={shareViaWhatsApp}
                            className="bg-[#25D366] text-white"
                          />
                          <ShareButton
                            icon={FaFacebookF}
                            label="Share on Facebook"
                            onClick={shareViaFacebook}
                            className="bg-[#1877F2] text-white"
                          />
                          <ShareButton
                            icon={FaEnvelope}
                            label="Share via Email"
                            onClick={shareViaEmail}
                            className="bg-gradient-to-r from-purple-600 to-purple-500 text-white"
                          />
                        </div>
                      </motion.div>
                    </div>

                    <motion.div className="bg-white rounded-xl shadow-sm p-4 border border-purple-100">
                      <div className="grid grid-cols-10 gap-3">
                        <div className="col-span-4">
                          <div className="flex items-center justify-between mb-3">
                            <div className="flex items-center gap-2">
                              <div className="bg-purple-50 p-1.5 rounded-lg">
                                <FaTrophy className="text-base text-purple-600" />
                              </div>
                              <h2 className="text-base font-bold text-gray-800">
                                Referral Stats
                              </h2>
                            </div>
                          </div>
                          <StatCard
                            value={referredUsers.length}
                            label="Total Invites"
                            icon={FaShare}
                          />
                        </div>
                        <div className="col-span-6">
                          <div className="bg-white rounded-lg border border-purple-100 overflow-hidden h-[180px] flex flex-col">
                            <div className="p-3 border-b border-purple-100 bg-purple-50">
                              <h3 className="text-sm font-semibold text-purple-800">
                                Referred Users
                              </h3>
                            </div>
                            <div className="flex-1 overflow-y-auto">
                              {referredUsers.length > 0 ? (
                                referredUsers.map((user) => (
                                  <UserCard
                                    key={user._id}
                                    name={user.firstName}
                                    date={user.createdAt?.split("T")[0]}
                                  />
                                ))
                              ) : (
                                <div className="p-4 text-center text-sm text-gray-500">
                                  No referrals yet
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            <AnimatePresence>
              {showToast && (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-purple-900 text-white px-3 py-2 rounded-lg shadow-lg flex items-center gap-2 text-xs z-50"
                >
                  <FaCopy className="text-cyan-200 text-xs" />
                  <span>Code copied!</span>
                </motion.div>
              )}
            </AnimatePresence>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ReferralSystem;