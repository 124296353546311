import { Box, Button, Typography, useTheme } from "@mui/material";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Commercial from "./Commercial";
import WidgetWrapper from "../../../components/WidgetWrapper";
import FlexBetween from "../../../components/FlexBetween";
import { useGetBrandsByUserQuery } from "../../../store/services/brandService";
import CreateCommercialModal from "./CreateCommercialModal";
import EditCommercialModal from "./EditCommercialModal";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, LocalOfferOutlined } from "@mui/icons-material";

const ProfileCommercialWidget = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [modal_open, setmodal_open] = useState(false);
  const token = useSelector((state) => state.authReducer.token);
  const pets = useSelector((state) => state.authReducer.user.pets);
  const { _id, picturePath } = useSelector((state) => state.authReducer.user);

  const { data: branddata } = useGetBrandsByUserQuery(_id);

  const [alldata, setalldata] = useState(null);
  const [editmodalopen, seteditmodalopen] = useState(false);

  let i = 1;
  return (
    <WidgetWrapper>
      <EditCommercialModal
        open={editmodalopen}
        setOpen={seteditmodalopen}
        userdata={alldata}
      />
      <CreateCommercialModal open={modal_open} setOpen={setmodal_open} />
      <FlexBetween gap="0.5rem" pb="1.1rem">
        <h2>My Business</h2>
        <Button
          size="small"
          onClick={() => setmodal_open(true)}
          sx={{
            color: palette.background.alt,
            backgroundColor: palette.primary.main,
            borderRadius: "3rem",
          }}
        >
          Add
        </Button>
      </FlexBetween>

      <div className="container ">
        <div className="row  ">
          <div class="profiles-display">

            {branddata?.length > 0 && (
              <Swiper
                navigation={true}
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={10}
                Navigation
                className="w-full h-full"
                breakpoints={{
                  0: {
                    slidesPerView: 1.5,
                  },
                  768: {
                    slidesPerView: 1.5,
                  },
                  1080: {
                    slidesPerView: 4.5,
                  },
                  1280: {
                    slidesPerView: 4.5,
                  },
                }}
              >
                {branddata?.map((friend, index) => {
                  if (i >= 5) {
                    i = 1;
                  } else {
                    i++;
                  }
                  return (
                    <SwiperSlide
                      className="w-full h-full overflow-hidden  relative"
                      key={index}
                      virtualIndex={index}
                    >
                      <div
                       
                        className="w-full h-full"
                      >
                        <div
                          class="one-profile online"
                          
                        >
                         <div
                            className="img"
                            onClick={() =>
                              navigate(`/commericaldetails/${friend._id}`)
                            }
                          >
                            <img
                              src={`https://api.petzoy.in/assets/${friend.picturePath}`}
                            />
                          </div>
                          <h4 onClick={() => {
                            setalldata(friend);
                            seteditmodalopen(true);
                          }}>{friend.name} <EditOutlined /></h4>
                           <LocalOfferOutlined
          onClick={() => navigate(`/mycoupons/${friend._id}`)} // Navigate to another screen
          style={{ cursor: "pointer", marginLeft: 10 }} // Add styling for spacing and cursor
        />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}

            {/* {branddata?.length > 0 &&
              branddata?.map((friend) => (
                <div
                  class="one-profile online"
                  onClick={() => {
                    setalldata(friend);
                    seteditmodalopen(true);
                  }}
                >
                  <div class="img">
                    <img
                      src={`https://api.petzoy.in/assets/${friend.picturePath}`}
                    />
                  </div>
                  <h4>{friend.name}</h4>
                </div>
              ))} */}

          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default ProfileCommercialWidget;
