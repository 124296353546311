import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaEllipsisH, FaUserFriends, FaThumbsUp, FaComment, FaShare, FaBirthdayCake, FaVideo, FaGlobeAmericas } from 'react-icons/fa';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Modal,
    TextField,
    Typography,
    useTheme,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    useMediaQuery,
    MenuItem,
    FormControlLabel,
    Checkbox,
    styled,
    Badge,
  } from "@mui/material";
import { useSelector } from 'react-redux';
import { useGetNotificationsQuery } from '../../store/services/notificationService';
import { useGetUserCouponsQuery } from '../../store/services/authService';
import Coupons from './Coupons';
import { useCreateCouponMutation, useGetCouponsByBusinessQuery } from '../../store/services/BusinessCoupons';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
const BusinessCoupons = () => {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState('all');
  const [showMarkAllAsRead, setShowMarkAllAsRead] = useState(false);
  const { mainId } = useParams();
  const _id = useSelector((state) => state.authReducer.user?._id);
  const { data:mainnotifications = [], refetch } = useGetNotificationsQuery(_id);
  const { data: couponData = [], error: couponerror } = useGetCouponsByBusinessQuery(mainId);


  const [formdata, setformdata] = useState({
    businessId: mainId,          // Business ID
    userId: _id,              // User ID (can be fetched from the logged-in user's data)
    code: "",                // Coupon code
    description: "",         // Coupon description
    minimumOrderValue: "",   // Minimum order value for the coupon to be valid
    discountType: "percentage", // Discount type (percentage or flat)
    discountValue: "",       // Discount value (percentage or flat amount)
    expiryDate: "2024-12-31", // Expiry date for the coupon
         // Referral milestone required to use the coupon
  });

  const [createCoupon, { isLoading, isSuccess }] = useCreateCouponMutation();
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createCoupon(formdata).unwrap();
      if (response) {
        toast.success("Coupon Generated")
        setformdata({
          businessId: "",
          userId: "",
          code: "",
          description: "",
          minimumOrderValue: "",
          discountType: "percentage",
          discountValue: "",
          expiryDate: "2024-12-31",
          
        }); // Reset form data on success
      }
    } catch (err) {
      console.error("Coupon creation failed:", err);
    }
  };
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const handleFormInput = (event) => {
    console.log(event);
    const { name, value } = event.target;
    setformdata({
      ...formdata,
      [name]: value,
    });
  };
  useEffect(() => {
    if(notifications){

      setNotifications(mainnotifications)
    }
  }, [mainnotifications])
  
  const markAllAsRead = () => {
    setNotifications(notifications.map(n => ({ ...n, status: "read" })));
    setShowMarkAllAsRead(false);
    // API call to mark all notifications as read
  };


  return (
    <div className="min-h-screen max-w-2xl mx-auto mt-4 bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Manage Coupons</h1>
          {showMarkAllAsRead && (
            <button
              onClick={markAllAsRead}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium"
            >
              Mark all as read
            </button>
          )}
        </div>
        <div className="mt-2 flex space-x-2">
          <button
            onClick={() => setFilter('all')}
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              filter === 'all' ? 'bg-blue-100 text-blue-800' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Create New
          </button>
          
          <button
            onClick={() => setFilter('coupons')}
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              filter === 'coupons' ? 'bg-blue-100 text-blue-800' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            My Coupons
          </button>
        </div>
      </div>
      <div className="divide-y divide-gray-100">
        {filter == "coupons" ? couponData?.coupons?.map((notification) => 


{
  return(
    <Coupons notification={notification}/>
   
        )}):







        <div>
      <Box
        sx={{
          padding: 10,
          overflowY: "scroll",
        }}
      >
        
        <TextField
          label="Coupon Code"
          value={formdata.code}
          type="text"
          name="code"
          required
          onChange={handleFormInput}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={formdata.description}
          type="text"
          name="description"
          onChange={handleFormInput}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Minimum Order Value"
          value={formdata.minimumOrderValue}
          type="number"
          name="minimumOrderValue"
          required
          onChange={handleFormInput}
          fullWidth
          margin="normal"
        />
        <TextField
          select
          label="Discount Type"
          value={formdata.discountType}
          onChange={handleFormInput}
          name="discountType"
          fullWidth
          margin="normal"
        >
          <MenuItem value="percentage">Percentage</MenuItem>
          <MenuItem value="flat">Flat</MenuItem>
        </TextField>
        <TextField
          label="Discount Value"
          value={formdata.discountValue}
          type="number"
          name="discountValue"
          required
          onChange={handleFormInput}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Expiry Date"
          value={formdata.expiryDate}
          type="date"
          required
          name="expiryDate"
          onChange={handleFormInput}
          fullWidth
          margin="normal"
        />
       
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          {isLoading ? "Creating..." : "Create Coupon"}
        </Button>

       
      </Box>
    </div>}
            
      </div>
    </div>
  );
};

export default BusinessCoupons;